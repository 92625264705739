.information-form {
    width: 100%;
}
/* Chrome, Safari, Edge, Opera */
.information-form input::-webkit-outer-spin-button,
.information-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.information-form input[type=number] {
    -moz-appearance: textfield;
}

.information-form .form-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.information-form .form-wrapper > div:first-child {
    width: 100%;
    margin-right: 1rem;
}

.information-form .form-wrapper > div:last-child {
    width: 100%;
    margin-left: 1rem;
}

.information-form .form-wrapper .form > .form-label {
    margin-bottom: 0.5rem;
    display: inline-block;
    color: var(--color-text-black);
    width: 100%;
    font-weight: 600;
}

.information-form .form-wrapper .form {
    margin-bottom: 1rem !important;
}
.information-form .option-tag>img {
    height: 2rem !important;
    width: 2rem !important;
}

.information-form .upload-image-preview {
    position: relative;
    width: 100%;
}

.information-form .upload-image-preview > img,
.information-form .upload-image-preview > video {
    width: 100%;
    border-radius: 10px;
    height: 140px;
    object-fit: contain;
    border: 1px solid var(--color-border);
    background-color: var(--color-input);
}

.information-form .upload-image-preview > .btn-remove {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--color-bg-pink);
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 0.25rem;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.information-form .upload-drag-and-drop {
    padding: 1.5rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid var(--color-border);
    background-color: var(--color-input);
}

.ant-upload {
    width: 100%;
}

.information-form .upload-drag-and-drop > i {
    font-size: 1.5em;
    margin-bottom: 1rem;
    display: inline-block;
    color: var(--color-secondary);
}

.information-form .upload-drag-and-drop > p {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    color: var(--color-secondary);
}

.information-form .upload-drag-and-drop:hover,
.information-form .upload-drag-and-drop:hover > i,
.information-form .upload-drag-and-drop:hover > p,
.information-form .upload-drag-and-drop.active,
.information-form .upload-drag-and-drop.active > i,
.information-form .upload-drag-and-drop.active > p {
    border-color: var(--color-input-focus);
    /* color: var(--color-active); */
}


.information-form .upload-drag-and-drop > p.upload-title span.upload-highlight {
    color: var(--primary-button);
}

.information-form .upload-drag-and-drop > p.upload-subtitle {
    color: var(--color-text-gray-dark);
    font-weight: 400;
    font-size: .8rem;
}

.information-form .button-wrapper {
    margin-top: 2rem;
}

.information-form .button-wrapper .button-primary {
    margin-right: 1rem;
    background-color: var(--color-primary);
    border: none;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}

.information-form .button-wrapper .button-primary:disabled {
    opacity: 0.5;
    cursor: disabled;
}

.information-form .button-wrapper .button-secondary {
    margin-right: 1rem;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}

.information-form .form-group {
    display: flex;
    align-items: center;
}

.information-form .form input[type='color'] {
    background-color: rgb(243, 156, 18);
    border: none;
    width: 2rem;
    height: 2rem;
    padding: 0px;
    border-radius: 5px;
}

.information-form .input-group {
    display: flex;
    align-items: center;
}

.information-form .input-group > div:last-child {
    background-color: var(--color-stroke);
    padding: 0.3rem 1em;
}

.information-form .card-adsign .card-title > p {
    font-weight: normal;
}

.information-form .card-adsign-content .button-link {
    background-color: transparent;
}

.information-form .card-checkbox span {
    font-weight: normal;
}

.information-form .card-adsign-content .copy-popup {
    right: 0;
}

.information-form .form .form-split {
    display: flex;
    justify-content: space-between;
}

.information-form .form .form-split div:first-child {
    margin-right: 2rem;
}

.information-form .form .form-split label.form-label {
    margin-bottom: 0.5rem;
    display: inline-block;
}

.information-form .form-group span {
    display: inline-block;
    margin: 0px 0.5rem;
}


.information-form input.ant-input, .information-form textarea.ant-input {
    background-color: var(--color-input);
    border: 1px solid var(--color-border) !important;
    border-radius: 0.25rem !important;
    font-family: 'Inter', sans-serif;
    font-size: .9rem;
    padding: 0.5rem 1rem;
}

.information-form input.ant-input::placeholder,
.information-form textarea.ant-input::placeholder {
    color: var(--color-text-light);
}

.information-form input.ant-input:focus, .information-form textarea.ant-input:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--color-input-focus) !important;
}


.information-form .ant-picker {
    width: 100%;
    background-color: var(--color-input);
    border: 1px solid var(--color-border) !important;
    border-radius: 0.25rem !important;
    font-family: 'Inter', sans-serif;
    font-size: .9rem;
    padding: 0.5rem 1rem;
}

.information-form .ant-picker.ant-picker-focused {
    border-color: var(--color-input-focus) !important;
    box-shadow: none !important;
}

.information-form .ant-select-selector {
    width: 100%;
    background-color: var(--color-input) !important;
    border: 1px solid var(--color-border) !important;
    border-radius: 0.25rem !important;
    font-family: 'Inter', sans-serif;
    font-size: .9rem;
    padding: 0.15rem 1rem !important;
    height: auto !important;
}

.information-form .ant-select-open .ant-select-selector, .information-form .ant-select-focused .ant-select-selector {
    border-color: var(--color-input-focus) !important;
    box-shadow: none !important;
}

.information-form .btn-primary {
    color: #fff;
    background-color: var(--primary-button);
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
    height: auto;
    border: none;
    font-weight: 600;
    box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
    cursor: pointer;
}

.ant-picker-range .ant-picker-clear {
    right: 41px !important;
}

/*.information-form .ql-toolbar:first-child {*/
/*    display: none;*/
/*}*/

.information-form .playlist-wrapper {
    width: 100% !important;
}

.information-form .upload-chunk {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1rem;
}

.information-form .upload-file-wrapper {
    position: relative;
    width: 11rem;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f9f9f9;
}

.information-form .upload-remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 4px;
    z-index: 2;
}

.information-form .upload-remove-button:hover {
    background: rgba(255, 255, 255, 1);
}

.information-form .preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.information-form .preview-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.information-form .excel-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #2d6a4f;
}

.information-form .excel-preview svg {
    width: 40px;
    height: 40px;
    color: green;
}

.information-form .excel-preview p {
    margin-top: 5px;
    font-size: 12px;
    word-wrap: break-word;
    max-width: 100px;
}
